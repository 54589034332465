/* .App {
  background-color: #fbb448d9;
} */


/* Tabs section */

.nav {
    color: #252525 !important;
}

.nav .nav-item .nav-link.active {
    background-image: linear-gradient(#c0069b, #ff8733);
    color: #fff;
}

.nav-pills .nav-link.active {
    background-image: linear-gradient(#c0069b, #ff8733) !important;
    color: #fff;
}

.nav-pills .nav-link {
    border-radius: 0;
}

.nav-link:focus {
    background-image: linear-gradient(#c0069b, #ff8733);
    color: #fff;
}

.react-date-picker__wrapper {
    border: none !important;
}


/* FilterRooms Page Scroll Bar */

.b_table::-webkit-scrollbar {
    display: none;
}


/* Date Picker */

.react-date-picker__inputGroup {
    text-align: start !important;
}


/* Paragraph */

p {
    text-align: justify !important;
}


/* charu CSS */


.all-text-gradient {

    display: inline-block;
    background: linear-gradient(300deg, rgb(250, 163, 97) 0.3184713375796178%, rgb(255, 135, 51) 1.910828025477707%, rgb(192, 6, 155) 99.36305732484075%);
    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent !important;
}

h1.bg-bg-news-header.w-full.py-3.mt-0.text-slate-200 {
    font-size: 20px;
    font-weight: 400;
}

.text-slate-200 {
    font-size: 20px;
    font-weight: 400;
}

.color-box {

    background: linear-gradient(300deg, rgb(250, 163, 97) 0.3184713375796178%, rgb(255, 135, 51) 1.910828025477707%, rgb(192, 6, 155) 99.36305732484075%);
    min-height: 161px;
    padding: 20px;
    margin-bottom: 50px;
}

.desc {
    background: #E86740;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
}

.desc-dynamic {
    background: #E86740;
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    min-height: 70px;
}

.float-right {
    text-align: right;
}

.logo-image {
    width: 100%;
    max-width: 80px;
}